<template>
    <div id="TreePanelSelect">
        <div style="width: 164px;" @click.stop="listVisible = !listVisible">
            <CSSelect>
                <div
                    v-if="selectedName"
                    style="width: 130px; margin-left: 6px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;"
                >
                    {{ selectedName }}
                </div>
                <div
                    v-else
                    style="width: 130px; margin-left: 6px; font-size: 15px; color: #999;"
                >
                    {{ tipsText }}
                </div>
            </CSSelect>
        </div>
        <div class="tree-source-panel" @click.stop v-show="listVisible" :style="{ '--width': width }"
             @scroll="onScroll">
            <div v-if="!hideDefaultOption" @click="onClick('')">{{ tipsText }}</div>
            <div class="tree-item" @click="onClick(item[itemId], item[textName])" v-for="(item, index) in traverseData"
                 :key="index">{{ item[textName] }}
            </div>
        </div>
    </div>
</template>

<script>
import CSSelect from "@/components/common/CSSelect"

export default {
    name: "TreePanelSelect",
    components: {
        CSSelect,
    },
    watch: {
        listVisible(visible) {
            if (visible) {
                this.$emit('refresh')
            }
        },
        defaultSelect(val) {
            if (val !== '') {
                this.selectedName = val;
            }
        }
    },
    props: {
        // 下拉框的宽度
        width: {
            default: "250px",
            type: String,
        },
        // 隐藏请选择
        hideDefaultOption: {
          type: Boolean,
          default: false,
        },
        // 距离组件底部多远触发加载
        distance: {
            default: 20,
            type: Number
        },
        defaultSelect: String,
        // 显示的文字
        textName: {
            default: "title",
            type: String,
        },
        // 提示文案
        tipsText: {
            default: "请选择",
            type: String,
        },
        // 遍历的数据
        traverseData: Array,
        // 当前每一项的id
        itemId: {
            default: "id",
            type: String
        },
        // 判断是否是小程序调用
        isApplets: {
            default: false,
            type: Boolean
        }
    },
    mounted() {
        document.body.addEventListener('click', () => {
            this.listVisible = false;
        })
        if (this.defaultSelect) {
            this.selectedName = this.defaultSelect;
        }
    },
    data() {
        return {
            timer: null,
            // 下拉框显示的内容
            selectedName: "",
            // 列表的显隐
            listVisible: false,
            // 页数,必要时也可写成其他参数,主要应对小程序接口,如：87行
            pageNo: 1,
        }
    },
    methods: {
        onScroll(e) {
            const {scrollTop, clientHeight, scrollHeight} = e.target;
            if (scrollTop !== 0 && scrollHeight - scrollTop - clientHeight < this.distance + scrollHeight % clientHeight) {
                if (this.timer) {
                    clearInterval(this.timer);
                }
                this.timer = setTimeout(() => {
                    if (this.isApplets) {
                        this.pageNo = this.traverseData[this.traverseData.length - 1][this.itemId];
                    } else {
                        this.pageNo++;
                    }
                    this.$emit('loadMore', this.pageNo)
                }, 500);
            }
        },
        onClick(id, itemName) {
            this.selectedName = itemName;
            this.$emit('onClick', id);
            this.listVisible = false;
        }
    }
};
</script>


<style lang="stylus" scoped>
#TreePanelSelect
    position relative

.tree-source-panel
    width var(--width, 250px)
    max-height 300px
    overflow-y auto
    background #FFFFFF
    z-index 1
    padding 5px 10px 10px 10px
    box-shadow 0 0 10px 0 rgba(0, 0, 0, 0.15)
    border-radius 10px
    position absolute
    left 0
    padding 0 10px
    border-top 10px solid #fff
    border-bottom 10px solid #fff

    div
        font-size 16px
        line-height 20px
        cursor pointer
        padding 10px 0
        border-bottom 1px solid #f0f0f0
</style>
