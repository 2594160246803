<template>
    <div id="guidePassage">
        <div class="result-panel" style="margin-top: 0; padding: 15px 30px;">
            <p style="font-size: 14px; color: #000; margin-bottom: 10px;">公众号闸机开门通知备注文案</p>
            <textarea
                type="number"
                style="
                width: 300px;
                background-color: #f0f0f0;
                border: 1px solid #f0f0f0;
                border-radius: 4px;
                height: 100px;
                font-size: 14px;
                resize: none;
                padding-left: 10px;
                margin-bottom: 20px;
          "
                placeholder="限50个字"
                maxlength="50"
                v-model="setupWelcomeMessageInfo.content"
            ></textarea>
            <div style="font-size: 14px; color: #000; line-height: 14px; margin-right: 30px;">
                <div style="display: inline-block; vertical-align: top; margin: 2px 30px 20px 0;">引导内容</div>
                <div
                    class="content"
                >
                    <div>
                        <div class="content-checkbox" @click="open">

                            <img src="@/assets/iconK.svg" v-if="!setupWelcomeMessageInfo.enableForward">
                            <img src="@/assets/iconS.svg" v-else>
                            <span>开启</span>
                        </div>
                        <div class="content-checkbox" @click="close">
                            <img src="@/assets/iconK.svg" v-if="setupWelcomeMessageInfo.enableForward">
                            <img src="@/assets/iconS.svg" v-else>
                            <span>关闭</span>
                        </div>
                    </div>
                    <div v-if="setupWelcomeMessageInfo.enableForward">
                        <div
                            class="content-checkbox"
                            v-for="item in welcomeMessageList"
                            :key="item.id"
                            @click="setupWelcomeMessageChange(item)"
                        >
                            <img :src="setupWelcomeMessageInfo.target == item.id ? icon3 :  icon5">
                            <span>{{ item.name }}</span>
                        </div>
                    </div>
                    <div v-if="setupWelcomeMessageInfo.target == 3">
                        <TreePanelSelect
                            :traverseData="itemList"
                            :isApplets="true"
                            :default-select="checkRefInfo[setupWelcomeMessageInfo.target].name"
                            @loadMore="getItemList"
                            textName="name"
                            @onClick="getId"
                            tipsText="请选择商品"
                        >
                        </TreePanelSelect>
                    </div>
                    <div v-if="setupWelcomeMessageInfo.target == 4">
                        <TreePanelSelect
                            :traverseData="userQueryItemList"
                            :isApplets="true"
                            @loadMore="getItemList"
                            textName="name"
                            :default-select="checkRefInfo[setupWelcomeMessageInfo.target].name"
                            @onClick="getId"
                            tipsText="请选择企业服务"
                        >
                        </TreePanelSelect>
                    </div>
                    <div v-if="setupWelcomeMessageInfo.target == 5">
                        <TreePanelSelect
                            :traverseData="serviceItemsList"
                            :isApplets="true"
                            @loadMore="getItemList"
                            textName="itemsName"
                            :default-select="checkRefInfo[setupWelcomeMessageInfo.target].name"
                            itemId="itemsId"
                            @onClick="getId"
                            tipsText="请选择上门服务"
                        >
                        </TreePanelSelect>
                    </div>
                    <div v-if="setupWelcomeMessageInfo.target == 7">
                        <TreePanelSelect
                            :traverseData="postLists"
                            @loadMore="getPostList"
                            @onClick="getId"
                            :default-select="checkRefInfo[setupWelcomeMessageInfo.target].name"
                            tipsText="请选择帖子"
                            :isApplets="true"
                        >
                        </TreePanelSelect>
                    </div>
                    <div v-if="setupWelcomeMessageInfo.target == 8">
                        <TreePanelSelect
                            :traverseData="noticeList"
                            :isApplets="true"
                            :default-select="checkRefInfo[setupWelcomeMessageInfo.target].name"
                            @loadMore="getOfficialList"
                            @onClick="getId"
                            tipsText="请选择公告"
                        >
                        </TreePanelSelect>
                    </div>
                    <div v-if="setupWelcomeMessageInfo.target == 9">
                        <TreePanelSelect
                            :traverseData="activityList"
                            :isApplets="true"
                            :default-select="checkRefInfo[setupWelcomeMessageInfo.target].name"
                            @loadMore="getOfficialList"
                            @onClick="getId"
                            tipsText="请选择活动"
                        >
                        </TreePanelSelect>
                    </div>
                    <button
                        type="button"
                        class="btn btn-primary"
                        style="margin-top: 20px;padding: 0;width: 50px;height: 30px"
                        @click="setupWelcomeMessageBtn"
                    >
                        保存
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as icon5 from "@/assets/iconK.svg";
import * as icon3 from "@/assets/iconS.svg";
import TreePanelSelect from "@/components/common/TreePanelSelect";

import {
    setupWelcomeMessage,
    queryWelcomeMessage,
    itemList,
    userQueryItems,
    serviceItems,
    userQueryOfficial,
    postList
} from "@/requestUrl";

export default {
    components: {
        TreePanelSelect,
    },
    data() {
        return {
            icon5,
            icon3,
            checkRefInfo: {
                1: {},
                2: {},
                3: {},
                4: {},
                5: {},
                6: {},
                7: {},
                8: {},
                9: {},
            },
            // 设置通行引导信息
            setupWelcomeMessageInfo: {
                regionCode: "",                       //园区编码必填，18位
                content: "",             //备注文案内容，必填，50字以内
                enableForward: false,                  //true/false 引导内容开启状态，必填
                landingPage: "",                      //引导内容开启时，跳转小程序页面路径（如有参数需拼接好参数），可以直接跳转的那种
                target: "",                           //跳转类型，引导开启时必填，1. 小程序首页; 2. 小程序通行页面; 3. 闪购; 4. 企业服务; 5. 上门服务; 6. 签到种苹果; 7. 社区帖子; 8. 公告; 9. 活动
                refId: "",                            //跳转携带的参数（比如商品id，帖子id），引导开启时必填
            },
            // 引导内容
            welcomeMessageList: [
                {
                    id: 1,
                    src: icon5,
                    name: "小程序首页",
                    landingPage: "/pages/index/index",
                },
                {
                    id: 2,
                    src: icon5,
                    name: "小程序通行页",
                    landingPage: "/pages/openDoor/openDoor",
                },
                {
                    id: 3,
                    src: icon5,
                    name: "闪购",
                    landingPage: "/packageMall/pages/mall/commodity/commodityDetails",
                },
                {
                    id: 4,
                    src: icon5,
                    name: "企业服务",
                    landingPage: "/subPackage/enterpriseService/enterpriseServiceDetail/enterpriseServiceDetail",
                },
                {
                    id: 5,
                    src: icon5,
                    name: "上门服务",
                    landingPage: "/packageVisiting/visitingServiceDetail/visitingServiceDetail",
                },
                {
                    id: 6,
                    src: icon5,
                    name: "签到种苹果",
                    landingPage: "/subPackage/MyWallet/apple/apple",
                },
                {
                    id: 7,
                    src: icon5,
                    name: "社区帖子",
                    landingPage: "/subPackage/community/communityContentParticulars/communityContentParticulars",
                },
                {
                    id: 8,
                    src: icon5,
                    name: "公告",
                    landingPage: "/subPackage/announcement/noticeAffiche/noticeAffiche",
                },
                {
                    id: 9,
                    src: icon5,
                    name: "活动",
                    landingPage: "/subPackage/myApply/activityParticulars/activityParticulars",
                }
            ],
            // 商品列表
            itemList: [{
                name: "系统随机选择",
                id: 0,
            }],
            // 企业服务列表
            userQueryItemList: [{
                name: "系统随机选择",
                id: 0,
            }],
            // 上门服务列表
            serviceItemsList: [{
                itemsName: "系统随机选择",
                itemsId: 0,
            }],
            // 公告列表
            noticeList: [],
            // 活动列表
            activityList: [],
            // 帖子列表
            postLists: [],
            // 搜索帖子信息
            searchPost: {
                regionCode: this.$vc.getCurrentRegion().code,       //区域code 必填
                updateTime: "",        //首次为空
                type: [1, 2, 3, 4, 8],
                title: "",             //标题
            },
            updateTime: "",
        }
    },
    async created() {
        // 初始获取通行引导设置状态
        const welcomeRes = await this.getWelcomeMessage();
        const _this = this;
        // [3, 4, 5, 7, 8, 9]
        function checkedRef(welcomeRes, res, type) {
            // const checkRefInfo = _this.checkRefInfo;
            if (welcomeRes.refId === 0) {
                /*checkRefInfo[type] = {
                    id: welcomeRes.refId,
                    name: '系统随机选择'
                };
                _this.checkRefInfo = {};*/
                _this.$set(_this.checkRefInfo, type, {
                    id: welcomeRes.refId,
                    name: '系统随机选择'
                })
                return ;
            }
            if (welcomeRes && (typeof type === 'number' ? welcomeRes.target === type : type.includes(welcomeRes.target))) {
                console.log('开始选择帖子');
                const targetPropertyName = {
                    3: {idName: 'id', textName: 'name'},
                    4: {idName: 'id', textName: 'name'},
                    5: {idName: 'itemsId', textName: 'itemsName'},
                    7: {idName: 'id', textName: 'title'},
                    8: {idName: 'id', textName: 'title'},
                    9: {idName: 'id', textName: 'title'}
                }
                res.forEach(val => {
                    if (val[targetPropertyName[welcomeRes.target].idName] === welcomeRes.refId) {
                        console.log(val, '之前选中的内容');
                        _this.$set(_this.checkRefInfo, welcomeRes.target, {
                            id: welcomeRes.refId,
                            name: val[targetPropertyName[welcomeRes.target].textName],
                        })
                    }
                })
            }
        }

        // 获取商品列表
        this.getItemList().then(res => checkedRef(welcomeRes, res, 3));
        // 获取企业服务列表
        this.getUserQueryItems().then(res => checkedRef(welcomeRes, res, 4));
        // 获取上门服务
        this.getServiceItems().then(res => checkedRef(welcomeRes, res, 5));
        // 获取帖子列表
        this.getPostList().then(res => checkedRef(welcomeRes, res, 7));
        // 查询公告,活动列表
        this.getOfficialList().then(res => checkedRef(welcomeRes, res, [8, 9]));
    },
    methods: {
        // 赋值id的方法
        getId(id) {
            this.setupWelcomeMessageInfo.refId = id;
        },
        // 获取通行引导设置状态
        getWelcomeMessage() {
            return this.$fly
                .get(queryWelcomeMessage, {
                    regionCode: this.$vc.getCurrentRegion().code,
                })
                .then(res => {
                    if (res.code != 0 || !res.data?.data) {
                        return;
                    }

                    this.setupWelcomeMessageInfo = res.data.data ?? {};
                    this.setupWelcomeMessageInfo.content === '' ? '您可通过每日签到/购物/论坛发帖等方式，获取苹果积分，积分越多兑换现金越多。' : this.setupWelcomeMessageInfo.content;
                    this.refId = res.data.data.refId ?? '';
                    this.target = res.data.data.target ?? '';
                    if (this.setupWelcomeMessageInfo.landingPage) {
                        this.setupWelcomeMessageInfo.landingPage = this.setupWelcomeMessageInfo.landingPage.split('?')[0];
                    }
                    this.welcomeMessageList.forEach(item => {
                        item.src = icon5;
                        if (item.id == res.data.data.target) {
                            item.src = icon3
                        }
                    })
                    return res.data.data;
                })
        },
        // 获取商品列表
        getItemList(lastId) {
            let params = {
                regionCode: this.$vc.getCurrentRegion().code,
            }
            if (lastId) {
                params.lastId = lastId;
            }
            return this.$fly
                .get(itemList, params)
                .then(res => {
                    if (res.code != 0) {
                        return [];
                    }
                    this.itemList.push(...res.data);
                    return res?.data || [];
                })
        },
        // 获取企业服务列表
        getUserQueryItems(lastId) {
            let params = {
                regionId: this.$vc.getCurrentRegion().code,
            }
            if (lastId) {
                params.lastId = lastId;
            }
            return this.$fly
                .post(userQueryItems, params)
                .then(res => {
                    if (res.code != 0) {
                        return [];
                    }
                    this.userQueryItemList.push(...res.data);
                    return res?.data || [];
                })
        },
        // 获取上门服务列表
        getServiceItems(lastId) {
            let params = {
                regionId: this.$vc.getCurrentRegion().code,
            }
            if (lastId) {
                params.lastId = lastId;
            }
            return this.$fly
                .post(serviceItems, params)
                .then(res => {
                    if (res.code != 0) {
                        return [];
                    }
                    this.serviceItemsList.push(...res.data);
                    return res?.data || [];
                })
        },
        // 获取帖子
        getPostList(id) {
            return this.$fly
                .post(postList, {
                    regionCode: this.$vc.getCurrentRegion().code,
                    types: [1, 2, 3, 4, 8],
                    updateTime: this.updateTime,
                })
                .then(res => {
                    if (res.code != 0) {
                        return [];
                    }
                    this.postLists.push(...res.data.posts);
                    this.updateTime = res.data.updateTime;
                    return res?.data?.posts || [];
                })
        },
        // 查询公告,活动接口
        getOfficialList(lastId) {
            let params = {
                regionCode: this.$vc.getCurrentRegion().code,
                lastId: 0
            }
            if (lastId) {
                params.lastId = lastId;
            }
            return this.$fly
                .post(userQueryOfficial, params)
                .then(res => {
                    if (res.code != 0) {
                        return;
                    }
                    res.data.forEach(item => {
                        if (item.type == 5) {
                            this.noticeList.push(item);
                        } else if (item.type == 6) {
                            this.activityList.push(item);
                        }
                    })
                    return res?.data || [];
                })
        },
        // 设置通行引导点击引导内容事件
        setupWelcomeMessageChange(item) {
            this.setupWelcomeMessageInfo.target = item.id;
            this.setupWelcomeMessageInfo.landingPage = item.landingPage;
            this.setupWelcomeMessageInfo.refId = this.checkRefInfo[item.id].id || '';
        },
        // 设置通行引导保存按钮
        setupWelcomeMessageBtn() {
            let refId = this.setupWelcomeMessageInfo.refId;
            console.log(refId);
            let params = {
                regionCode: this.$vc.getCurrentRegion().code,
                enableForward: this.setupWelcomeMessageInfo.enableForward,
                content: this.setupWelcomeMessageInfo.content ?? '',
                landingPage: refId ? this.setupWelcomeMessageInfo.landingPage + "?id=" + refId : this.setupWelcomeMessageInfo.landingPage,
                target: this.setupWelcomeMessageInfo.target,
                refId: refId ?? null,
            }
            this.$fly
                .post(setupWelcomeMessage, params)
                .then(res => {
                    if (res.code != 0) {
                        this.$vc.toast("设置失败");
                        return;
                    }
                    this.$vc.toast("设置成功");

                })
        },
        open() {
            this.setupWelcomeMessageInfo.enableForward = true;
        },
        close() {
            this.setupWelcomeMessageInfo.enableForward = false;
            this.setupWelcomeMessageInfo.target = 0;
        }
    }
}
</script>

<style lang="stylus" scoped>
.content
.content-checkbox
    display inline-block
    margin 0 20px 14px 0
    img
        width 16px
        height 16px
        vertical-align middle
        margin-right 10px

    span
        font-size 14px
        vertical-align middle
</style>
